import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import { v4 as uuidv4 } from "uuid";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import MotForm from "../../components/mot-form";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import { Button } from "react-bootstrap";
import DropdownQuestion from "../../components/dropdown-question";
import ReactPlayer from "react-player";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const AdasCalibrationPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "adas-calibration" }) {
        slug
        adasCalibrationFields {
          adasCalibrationBanner {
            adasCalibrationBannerHeading
            adasCalibrationBannerBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          adasCalibrationContentSection {
            adasCalibrationContentHeading
            adasCalibrationContentText
          }
          adasCalibrationLeftImageRightContent {
            adasCalibrationRightContentHeading
            adasCalibrationRightContentText
            adasCalibrationRightContentButton {
              target
              title
              url
            }
            adasCalibrationLeftImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          adasCalibrationBackgroundOverlayContent {
            adasCalibrationOverlayHeading
            adasCalibrationOverlayContent
            adasCalibrationOverlayLink {
              title
              target
              url
            }
            adasCalibrationBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          adasCalibrationFaqSection {
            adasCalibrationFaqSectionHeading
            adasCalibrationFaqImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            adasCalibrationFaqs {
              adasCalibrationQuestion
              adasCalibrationAnswer
            }
          }
          adasCalibrationTwoColumnVideos {
            adasCalibrationTwoColumnHeading
            adasCalibrationVideos {
              adasCalibrationVideoContent
              adasCalibrationVideoFile {
                node {
                  altText
                  mediaItemUrl
                }
              }
              adasCalibrationVideoHeading
            }
          }
          adasCalibrationForm {
            adasCalibrationFormHeading
          }
          adasCalibrationServicesCards {
            adasCalibrationServicesCardsHeading
            adasCalibrationServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, adasCalibrationFields, slug },
  } = data;

  const {
    adasCalibrationBanner,
    adasCalibrationContentSection,
    adasCalibrationLeftImageRightContent,
    adasCalibrationBackgroundOverlayContent,
    adasCalibrationFaqSection,
    adasCalibrationTwoColumnVideos,
    adasCalibrationForm,
    adasCalibrationServicesCards,
  } = adasCalibrationFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${seoFields?.metaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "ADAS Calibration",
        item: {
          url: `${siteUrl}/what-we-do/adas-calibration`,
          id: `${siteUrl}/what-we-do/adas-calibration`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {adasCalibrationBanner && (
          <section>
            <ServiceHero
              title={adasCalibrationBanner.adasCalibrationBannerHeading}
              backgroundImage={
                adasCalibrationBanner.adasCalibrationBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                adasCalibrationBanner.adasCalibrationBannerBackgroundImage.node
                  ?.altText
              }
            />
          </section>
        )}
        {adasCalibrationContentSection &&
          !checkPropertiesForNull(adasCalibrationContentSection, [
            "adasCalibrationContentHeading",
          ]) && (
            <section className="pt-5 pb-4  pt-lg-8 position-relative">
              <Container>
                <Row>
                  <Col className="position-relative">
                    <h2 className=" text-center text-uppercase pb-5">
                      {
                        adasCalibrationContentSection.adasCalibrationContentHeading
                      }
                    </h2>

                    <SafeHtmlParser
                      htmlContent={
                        adasCalibrationContentSection?.adasCalibrationContentText
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {adasCalibrationLeftImageRightContent &&
          !checkPropertiesForNull(adasCalibrationLeftImageRightContent, [
            "adasCalibrationRightContentHeading",
          ]) && (
            <section className="pb-5 pb-lg-7">
              <Container>
                <Row className="g-5">
                  <Col lg={6}>
                    {" "}
                    <GatsbyImage
                      className="w-100 h-100"
                      image={
                        adasCalibrationLeftImageRightContent
                          .adasCalibrationLeftImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        adasCalibrationLeftImageRightContent
                          .adasCalibrationLeftImage.node?.altText
                      }
                    />
                  </Col>
                  <Col className="py-lg-5" lg={6}>
                    <h2 className="text-uppercase pb-3 fs-3">
                      {
                        adasCalibrationLeftImageRightContent.adasCalibrationRightContentHeading
                      }
                    </h2>

                    <SafeHtmlParser
                      htmlContent={
                        adasCalibrationLeftImageRightContent?.adasCalibrationRightContentText
                      }
                    />
                    {adasCalibrationLeftImageRightContent.adasCalibrationRightContentButton &&
                      adasCalibrationLeftImageRightContent
                        .adasCalibrationRightContentButton.url && (
                        <Row>
                          <Col className="text-center text-md-start">
                            <Button
                              style={{ borderRadius: "0px" }}
                              variant="secondary"
                              as={Link}
                              to={
                                adasCalibrationLeftImageRightContent
                                  .adasCalibrationRightContentButton.url
                              }
                              className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                              target={
                                adasCalibrationLeftImageRightContent
                                  .adasCalibrationRightContentButton.target ??
                                "_self"
                              }
                            >
                              {
                                adasCalibrationLeftImageRightContent
                                  .adasCalibrationRightContentButton.title
                              }
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {adasCalibrationBackgroundOverlayContent &&
          !checkPropertiesForNull(adasCalibrationBackgroundOverlayContent, [
            "adasCalibrationOverlayHeading",
          ]) && (
            <section>
              {" "}
              <BgImage
                style={{ backgroundPosition: "top" }}
                className=""
                alt=""
                image={getImage(
                  adasCalibrationBackgroundOverlayContent
                    .adasCalibrationBackgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                )}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative py-xl-10 py-5 "
                >
                  <Row className="justify-content-start">
                    <Col lg={7} className="">
                      <div className="position-relative p-4 p-md-5">
                        <div
                          style={{
                            zIndex: 1,
                            opacity: 0.8,
                            backgroundColor: "white",
                          }}
                          className="position-absolute h-100 w-100 start-0 bottom-0"
                        ></div>
                        <div
                          className="position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className=" pb-4 text-uppercase">
                            {
                              adasCalibrationBackgroundOverlayContent.adasCalibrationOverlayHeading
                            }
                          </h2>

                      
                          <SafeHtmlParser
                            htmlContent={
                              adasCalibrationBackgroundOverlayContent?.adasCalibrationOverlayContent
                            }
                          />

                          {adasCalibrationBackgroundOverlayContent.adasCalibrationOverlayLink &&
                            adasCalibrationBackgroundOverlayContent
                              .adasCalibrationOverlayLink.url && (
                              <Row>
                                <Col className="text-center text-md-start">
                                  <Button
                                    style={{ borderRadius: "0px" }}
                                    variant="secondary"
                                    as={Link}
                                    to={
                                      adasCalibrationBackgroundOverlayContent
                                        .adasCalibrationOverlayLink.url
                                    }
                                    className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                                    target={
                                      adasCalibrationBackgroundOverlayContent
                                        .adasCalibrationOverlayLink.target ??
                                      "_self"
                                    }
                                  >
                                    {
                                      adasCalibrationBackgroundOverlayContent
                                        .adasCalibrationOverlayLink.title
                                    }
                                  </Button>
                                </Col>
                              </Row>
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}

        {adasCalibrationFaqSection &&
          !checkPropertiesForNull(adasCalibrationFaqSection, [
            "adasCalibrationFaqSectionHeading",
          ]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row className="g-5">
                  <Col lg={6}>
                    {" "}
                    <GatsbyImage
                      className="w-100 d-none d-lg-block "
                      style={{ height: "40rem" }}
                      image={
                        adasCalibrationFaqSection.adasCalibrationFaqImage.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={
                        adasCalibrationFaqSection.adasCalibrationFaqImage.node
                          ?.altText
                      }
                    />
                    <GatsbyImage
                      className="w-100 d-lg-none"
                      image={
                        adasCalibrationFaqSection.adasCalibrationFaqImage.node
                          ?.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={
                        adasCalibrationFaqSection.adasCalibrationFaqImage.node
                          ?.altText
                      }
                    />
                  </Col>
                  <Col className="py-3" lg={6}>
                    <h2 className="text-uppercase pb-3 fs-3">
                      {
                        adasCalibrationFaqSection.adasCalibrationFaqSectionHeading
                      }
                    </h2>
                    {adasCalibrationFaqSection.adasCalibrationFaqs &&
                      adasCalibrationFaqSection.adasCalibrationFaqs.length >
                        0 && (
                        <>
                          {adasCalibrationFaqSection.adasCalibrationFaqs.map(
                            (item) => (
                              <DropdownQuestion
                                question={item.adasCalibrationQuestion}
                                answer={item.adasCalibrationAnswer}
                                key={uuidv4()}
                              />
                            )
                          )}
                        </>
                      )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {adasCalibrationTwoColumnVideos &&
          !checkPropertiesForNull(adasCalibrationTwoColumnVideos, [
            "adasCalibrationTwoColumnHeading",
          ]) && (
            <section className=" pt-lg-7">
              <Container>
                <Row>
                  <Col>
                    <h2 className="text-uppercase pb-4">
                      {
                        adasCalibrationTwoColumnVideos.adasCalibrationTwoColumnHeading
                      }
                    </h2>
                  </Col>
                </Row>
                {adasCalibrationTwoColumnVideos.adasCalibrationVideos &&
                  adasCalibrationTwoColumnVideos.adasCalibrationVideos.length >
                    0 && (
                    <Row className="gx-3 gy-5">
                      {adasCalibrationTwoColumnVideos.adasCalibrationVideos.map(
                        (item) => (
                          <Col lg={6} key={uuidv4()}>
                            <ReactPlayer
                              controls
                              className="w-100 h-auto"
                              url={
                                item.adasCalibrationVideoFile?.node
                                  ?.mediaItemUrl
                              }
                            />
                            <h3 className="text-uppercase py-3">
                              {item.adasCalibrationVideoHeading}
                            </h3>
                            <SafeHtmlParser htmlContent={item?.adasCalibrationVideoContent} />
                          </Col>
                        )
                      )}
                    </Row>
                  )}
              </Container>
            </section>
          )}

        <section id="book" className="pb-5 pb-lg-0 pt-5 pt-lg-7">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <h2 className="text-center pb-4">
                  {adasCalibrationForm.adasCalibrationFormHeading ||
                    "BOOK YOUR APPOINTMENT"}
                </h2>
                <div
                  style={{ background: "#F9F9F9", borderRadius: "18px" }}
                  className="p-md-5 p-4"
                >
                  <MotForm page="ADAS Calibration" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {adasCalibrationServicesCards &&
          !checkPropertiesForNull(adasCalibrationServicesCards, [
            "adasCalibrationServicesCardsHeading",
            "adasCalibrationServicesCards",
          ]) && (
            <OurServicesSection
              title={
                adasCalibrationServicesCards.adasCalibrationServicesCardsHeading
              }
              services={
                adasCalibrationServicesCards.adasCalibrationServicesCards?.nodes
              }
            />
          )}
      </Layout>
    </>
  );
};

export default AdasCalibrationPage;
